<template src="@/../../../templates/brand/components/templates/shop/product/ProductTile.html"> </template>

<script>
import _ from 'lodash/fp'
import { mapGetters } from 'vuex'
import accounting from 'accounting'

export default {
  name: 'ProductTile',
  props: {
    product: { default: {} },
    parentType: String,
    parentContainer: {}
  },
  data () {
    return {
      // locales: _.keys(this.$store.state.lang)
    }
  },
  computed: {
    ...mapGetters({
      appconfig: 'const'
    }),
    section () {
      if (!this.parentContainer) return ''
      return this.parentContainer.url
    },
    reProduct () {
      if (this.product[0] && !this.product.familyKey) return this.product[0]
      else return this.product
    },
    producturl () {
      // console.info('whats the url?',)
      let url
      if (this.parentType === 'collection') {
        url = {
          name: 'collectionProduct', 
          params: {
            collection: this.$route.params.collection, 
            fkey: this.reProduct.familyKey,
          }
        }
        if (this.reProduct.variationField.key)  url.params.vkey = this.reProduct.variationField.key
        // if (this.reProduct.variationField.key) {
        //   return (
        //     '/shop/' +
        //     this.section +
        //     '/product/' +
        //     this.reProduct.familyKey +
        //     '/' +
        //     this.reProduct.variationField.key
        //   )
        // } else {
        //   return (
        //     '/shop/' + this.section + '/product/' + this.reProduct.familyKey
        //   )
        // }
      } else if (this.parentType === 'program') {
        // url = `/programs/${this.parentContainer.url}/product/${this.reProduct.familyKey}`
        url = {name: 'programProduct', params: {program: this.parentContainer.url, fkey: this.reProduct.familyKey, vkey: this.reProduct.variationField.key || ''}}
      } else return 'what...'
      return url
    },
    lowestPrice () {
      return _.min(_.map(p => p.value, this.product.adjustments))
    },
    categoryClass () {
      return this.reProduct.category.map(c => c.url).join(' ')
    }
  },
  // component methods
  methods: {
    currencyLocale (val) {
      return accounting.formatMoney(
        val,
        this.appconfig.locale[this.$i18n.locale].currency
      )
    },
    twolines (value) {
      const _words = value.split(' ')
      if (_words.length < 4)
        return _words.slice(0, -1).join(' ') + '<br>' + _words.slice(-1)
      return value // return _words.slice(0,-2).join(' ') + '<br>' + _words.slice(-2).join(' ');
    }
  },
  // locales: {
  //   en: {
  //     title: {
  //       'get-it': 'Get it Now',
  //       'in-stock-gear': 'Get it Now',
  //       uniforms: 'Uniforms',
  //       'book-it': 'Book it',
  //       'customize-it': 'Customize It',
  //       prebook: 'Prebook'
  //     },
  //     viewBtn: {
  //       'get-it': 'Get it Now',
  //       'in-stock-gear': 'Get it Now',
  //       uniforms: 'Get it Now',
  //       'book-it': 'Book it Now',
  //       prebook: 'Get it Now',
  //       'customize-it': 'Customize It'
  //     }
  //   },
  //   fr: {
  //     title: {
  //       'get-it': 'Procurez-vous-le maintenant',
  //       'book-it': 'Réservez-le',
  //       'customize-it': 'Personnalisez-le'
  //     },
  //     viewBtn: {
  //       'get-it': 'Procurez-vous-le maintenant',
  //       'book-it': 'Réservez-le',
  //       'customize-it': 'Personnalisez-le'
  //     }
  //   }
  // }
}
</script>

<style src="@/../../../templates/brand/components/styles/shop/product/ProductTile.css"></style>
